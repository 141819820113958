import PropTypes from 'prop-types';
import React from 'react';

import { CookieProvider } from '../cookie-banner/context';
import CookieBanner from '../cookie-banner';
import SEO from '../seo';
import Tracking from '../tracking';

class BaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.handleFirstTab = this.handleFirstTab.bind(this);
    this.handleMouseDownOnce = this.handleMouseDownOnce.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.handleFirstTab);
    }
  }

  handleFirstTab(e) {
    if (typeof window !== 'undefined' && e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', this.handleFirstTab);
      window.addEventListener('mousedown', this.handleMouseDownOnce);
    }
  }

  handleMouseDownOnce() {
    if (typeof window !== 'undefined') {
      document.body.classList.remove('user-is-tabbing');

      window.removeEventListener('mousedown', this.handleMouseDownOnce);
      window.addEventListener('keydown', this.handleFirstTab);
    }
  }

  render() {
    const { children, seoProps } = this.props;
    return (
      <CookieProvider>
        <Tracking />
        <SEO {...seoProps} />
        {children}
        <CookieBanner />
      </CookieProvider>
    );
  }
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  seoProps: PropTypes.shape({}).isRequired,
};

export default BaseLayout;
