import React, { Component, createContext } from 'react';
import store from 'store';

const CookieContext = createContext();

export class CookieProvider extends Component {
  constructor() {
    super();
    /* eslint-disable react/no-unused-state */
    this.state = {
      dismissed: false,
      optedOut: false,
      toggleDismissed: () => {
        store.set('cookie_banner_dismissed', true);
        this.setState({ dismissed: true });
      },
      toggleOptedOut: () => {
        store.set('cookie_banner_opted_out', true);
        this.setState({ optedOut: true });
        window.location.reload();
      },
    };
  }

  componentDidMount() {
    const dismissed = store.get('cookie_banner_dismissed');
    const optedOut = store.get('cookie_banner_opted_out');
    this.setState((prev) => ({ ...prev, dismissed, optedOut }));
  }

  render() {
    return <CookieContext.Provider value={this.state} {...this.props} />;
  }
}

export const CookieConsumer = CookieContext.Consumer;
