import React from 'react';
import { Helmet } from 'react-helmet';
import { CookieConsumer } from '../cookie-banner/context';

export default () => (
  <CookieConsumer>
    {({ optedOut }) =>
      !optedOut && (
        <Helmet
          script={[
            {
              innerHTML: `
                (function (i, s, o, g, r, a, m) {
                  i['GoogleAnalyticsObject'] = r;
                  i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                  }, i[r].l = 1 * new Date();
                  a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                  a.async = 1;
                  a.src = g;
                  m.parentNode.insertBefore(a, m)
                })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
                ga('create', 'UA-103149497-1', 'auto');
                ga('send', 'pageview');
              `,
            },
            {
              async: true,
              src: 'https://www.googletagmanager.com/gtag/js?id=AW-812601444',
            },
            {
              type: 'text/javascript',
              innerHTML: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-812601444');
              `,
            },
          ]}
        />
      )
    }
  </CookieConsumer>
);
