import React from 'react';

export const Facebook = (props) => (
  <svg viewBox="0 0 40 40" fill="none" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm-2.187-23.036v-2.478-.03C17.81 13.911 17.8 11 21.629 11h2.758v3.155h-2.029c-.4 0-.807.416-.807.726v2.072h2.833c-.114 1.589-.348 3.042-.348 3.042h-2.499V29h-3.724v-9.005H16v-3.03h1.813z"
      fill="currentColor"
    />
  </svg>
);

export const Linkedin = (props) => (
  <svg viewBox="0 0 40 40" fill="none" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm-6.94-28.471c.373-.353.86-.529 1.46-.529.602 0 1.084.176 1.446.529.362.352.547.797.554 1.335.007.53-.176.973-.549 1.33-.373.355-.864.533-1.472.533h-.022c-.594 0-1.072-.178-1.434-.534-.362-.356-.543-.799-.543-1.33 0-.537.186-.982.56-1.334zm14.998 5.657c-.753-.825-1.75-1.238-2.988-1.238-.456 0-.87.057-1.244.17-.373.112-.688.27-.945.474a4.635 4.635 0 0 0-.614.566 4.698 4.698 0 0 0-.424.577v-1.536H18.27l.01.523c.008.349.012 1.424.012 3.226s-.007 4.153-.022 7.052h3.575v-6.027c0-.37.04-.665.119-.883.152-.371.382-.681.69-.932.308-.25.69-.376 1.146-.376.623 0 1.081.216 1.375.648.293.433.44 1.03.44 1.793V27h3.574v-6.19c0-1.592-.377-2.8-1.13-3.624zm-11.766-.987h-3.586v10.8h3.586V16.2z"
      fill="currentColor"
    />
  </svg>
);

export const Twitter = (props) => (
  <svg viewBox="0 0 40 40" fill="none" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm7.907-25.468a8.454 8.454 0 0 0 2.332-.638 8.063 8.063 0 0 1-2.028 2.096c.009.171.015.344.015.522C28.226 21.846 24.154 28 16.708 28c-2.291 0-4.415-.659-6.208-1.812a7.6 7.6 0 0 0 .969.056c1.892 0 3.64-.648 5.017-1.722a4.084 4.084 0 0 1-3.781-2.813c.245.055.507.07.766.07.372 0 .731-.044 1.06-.134a4.058 4.058 0 0 1-3.241-3.966v-.044c.54.296 1.178.484 1.828.497a4.04 4.04 0 0 1-1.798-3.355c0-.745.205-1.437.554-2.041 2 2.45 4.977 4.06 8.341 4.223a4.143 4.143 0 0 1-.093-.92A4.04 4.04 0 0 1 24.166 12a4.06 4.06 0 0 1 2.956 1.277 8.098 8.098 0 0 0 2.566-.977 4.016 4.016 0 0 1-1.78 2.232z"
      fill="currentColor"
    />
  </svg>
);

export const Pinterest = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13zm-3.425-5.286c-.082-.745-.155-1.89.034-2.706.17-.735 1.101-4.686 1.101-4.686s-.28-.565-.28-1.4c0-1.312.755-2.29 1.7-2.29.8 0 1.188.604 1.188 1.328 0 .517-.21 1.197-.43 1.913-.125.405-.253.822-.349 1.227-.22.94.47 1.704 1.392 1.704 1.67 0 2.954-1.768 2.954-4.319 0-2.258-1.616-3.836-3.925-3.836-2.673 0-4.242 2.013-4.242 4.093 0 .81.31 1.68.698 2.152a.285.285 0 01.066.27c-.042.178-.116.476-.175.717l-.086.352c-.041.173-.137.21-.315.127C7.733 14.812 7 13.09 7 11.707 7 8.733 9.153 6 13.208 6 16.467 6 19 8.331 19 11.446c0 3.25-2.042 5.866-4.875 5.866-.953 0-1.848-.497-2.154-1.084l-.586 2.242c-.212.819-.785 1.845-1.167 2.471l-.14.018a.514.514 0 01-.503-.245z"
      fill="currentColor"
    />
  </svg>
);
