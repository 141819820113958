import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const SITE_METADATA = graphql`
  {
    site {
      siteMetadata {
        keywords
        title
        siteUrl
        description
        email
        robots
        owner
        subject
        email
        imgUrl
      }
    }
  }
`;

const SEO = ({ title, description, keywords, imagePublicURL }) => (
  <StaticQuery query={SITE_METADATA}>
    {({
      site: {
        siteMetadata: {
          keywords: siteKeywords,
          title: siteTitle,
          description: siteDescription,
          siteUrl,
          imgUrl,
          email,
          subject,
          owner,
          robots,
        },
      },
    }) => {
      const schemaOrgJSONLD = [
        {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: siteUrl,
          sameAs: [
            'https://twitter.com/sprintlio',
            'https://facebook.com/sprintlio',
            'https://instagram.com/sprintlio',
            'https://www.linkedin.com/company/sprintlio/',
            'https://www.pinterest.com/sprintlio/',
          ],
          name: `${title} - ${subject}`,
          headline: subject,
          description: description || siteDescription,
          keywords: keywords || siteKeywords,
          creator: owner,
        },
      ];

      const sharingImage = imagePublicURL ? `${siteUrl}${imagePublicURL}` : imgUrl;

      return (
        <Helmet>
          <title lang="en">Sprintlio | {title}</title>
          <meta name="keywords" content={keywords || siteKeywords} />
          <meta name="subject" content={subject} />
          <meta name="description" content={description || siteDescription} />
          <meta name="copyright" content={owner} />
          <meta name="language" content="EN" />
          <meta name="robots" content={robots} />
          <meta name="revised" content={DateTime.local().toLocaleString(DateTime.DATE_MED)} />
          <meta name="abstract" content="Product" />
          <meta name="topic" content="Software" />
          <meta name="image" content={sharingImage} />
          <meta name="summary" content={description || siteDescription} />
          <meta name="classification" content="Business" />
          <meta name="author" content={`name, ${email}`} />
          <meta name="designer" content={siteTitle} />
          <meta name="copyright" content={siteTitle} />
          <meta name="reply-to" content={siteTitle} />
          <meta name="owner" content={owner} />
          <meta name="url" content={siteUrl} />
          <meta name="identifier-URL" content={siteUrl} />

          <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

          <meta property="og:title" content={`${siteTitle} | ${title || subject}`} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={siteUrl} />
          <meta property="og:site_name" content={siteTitle} />
          <meta property="og:image" content={sharingImage} />
          <meta property="og:description" content={description || siteDescription} />
          <meta property="og:email" content={email} />
          <meta property="og:locale" content="en_US" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@sprintlio" />
          <meta name="twitter:image" content={sharingImage} />
          <meta name="twitter:title" content={title || subject} />
          <meta name="twitter:description" content={description || siteDescription} />

          <meta name="p:domain_verify" content="aa082721c402edabf3752f90b5577b40" />
        </Helmet>
      );
    }}
  </StaticQuery>
);

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  imagePublicURL: PropTypes.string,
};
SEO.defaultProps = {
  title: '',
  description: '',
  keywords: null,
  imagePublicURL: '',
};

export default SEO;
