import React from 'react';
import { Link } from 'gatsby';
import { transparentize } from 'polished';
import { IoIosClose } from 'react-icons/io';
import styled from 'styled-components';
import { CookieConsumer } from './context';

const Wrap = styled.div`
  position: fixed;
  bottom: 20px;
  z-index: 2;
  background: ${transparentize(0.1, '#273478')};
  padding: 10px;
  display: flex;
  align-items: flex-start;
  width: calc(100% - 40px);
  max-width: 675px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;

  p {
    color: ${(props) => props.theme.colours.white};
    font-size: 12px;
    font-family: ${(props) => props.theme.fontFamilies};
    line-height: 1.4em;
    margin: 0 10px 0 0;
  }
`;

const OptOutButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  font-family: inherit;
  color: ${(props) => props.theme.colours.white};
  cursor: pointer;
`;

const Button = styled.button`
  background: ${(props) => props.theme.colours.white};
  border-radius: 3px;
  border: none;
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex: 0 0 24px;
  color: ${transparentize(0.1, '#273478')};

  svg {
    height: 22px;
    width: 22px;
  }
`;

const CookieBanner = () => (
  <CookieConsumer>
    {({ dismissed, optedOut, toggleOptedOut, toggleDismissed }) =>
      dismissed || optedOut ? (
        false
      ) : (
        <Wrap>
          <p>
            We use cookies to enhance your experience. To learn more about cookies and how to opt
            out, please visit our <Link to="/privacy">privacy policy</Link>. By continuing to use
            our site, you consent to our use of cookies.
            <OptOutButton type="button" onClick={toggleOptedOut} aria-label="Opt out of cookies">
              Opt-out
            </OptOutButton>
          </p>
          <Button type="button" onClick={toggleDismissed} aria-label="Dismiss cookie banner">
            <IoIosClose />
          </Button>
        </Wrap>
      )
    }
  </CookieConsumer>
);

export default CookieBanner;
