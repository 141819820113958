import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { A, Flex, Link } from '../styled';
import { headerHeight } from '../theme/measurements';
import Box from '../Box';
import Hamburger from '../hamburger';
import Logo from '../Logo';

const linkMq = '@media screen and (min-width: 810px)';
const linkProps = {
  mx: 17,
  height: 44,
  variant: 'body2',
  lineHeight: '44px',
  color: 'white80',
  width: '100%',
};
const linkSx = {
  textAlign: 'center',
  '&:hover': { color: 'white' },
  [linkMq]: { height: 'auto', width: 'auto' },
};

const HeaderA = ({ sx, ...props }) => <A {...linkProps} sx={{ ...linkSx, ...sx }} {...props} />;
HeaderA.propTypes = {
  sx: PropTypes.objectOf(PropTypes.any),
};
HeaderA.defaultProps = {
  sx: undefined,
};
const HeaderLink = ({ sx, ...props }) => (
  <Link {...linkProps} sx={{ ...linkSx, ...sx }} {...props} />
);
HeaderLink.propTypes = {
  sx: PropTypes.objectOf(PropTypes.any),
};
HeaderLink.defaultProps = {
  sx: undefined,
};

const Header = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Flex
      as="header"
      height={headerHeight}
      px={{ _: 20, header: 30 }}
      py={18}
      width="100%"
      bg="indigo"
      position="fixed"
      sx={{ top: 0, left: 0, zIndex: 2 }}
      {...props}
    >
      <Link to="/" mr={15} arial-label="Sprintlio home page">
        <Logo />
      </Link>
      <Box
        as="nav"
        flex="1"
        alignItems="center"
        display={{ _: open ? 'flex' : 'none', header: 'flex' }}
        position={{ _: 'absolute', header: 'static' }}
        bg={{ _: 'indigo', header: 'inherit' }}
        width={{ _: '100%', header: 'auto' }}
        flexDirection={{ _: 'column', header: 'row' }}
        sx={{ top: 67, left: 0 }}
      >
        <HeaderLink to="/#retrospective-features" colors="whiteHover">
          Features
        </HeaderLink>
        <HeaderLink to="/blog" colors="whiteHover">
          Blog
        </HeaderLink>
      </Box>
      <Hamburger
        role="button"
        tabIndex={0}
        onClick={() => setOpen((o) => !o)}
        active={open}
        display={{ _: 'inline', header: 'none' }}
        ml="auto"
        sx={{ cursor: 'pointer' }}
      />
    </Flex>
  );
};

Header.propTypes = {};

export default Header;
