import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import { A, Box, Inner, Link, Outer } from './styled';
import * as Social from '../images/social/social-icons';

const SocialWrap = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  & > a {
    font-size: 30px;
    color: ${(props) => props.theme.colours.white};
    &:hover {
      color: ${(props) => props.theme.colours.yellow};
    }
  }
`;

const Footer = () => (
  <Outer bg="indigo">
    <Inner
      display="flex"
      justifyContent="space-between"
      variant="body2"
      color="white"
      py={25}
      px={0}
      flexDirection={['column', 'column', 'row']}
      alignItems={['center', 'center', 'flex-start']}
    >
      <SocialWrap>
        <A href="https://facebook.com/sprintlio" aria-label="Sprintlio Facebook page">
          <Social.Facebook />
        </A>
        <A href="https://linkedin.com/company/sprintlio" aria-label="Sprintlio LinkedIn page">
          <Social.Linkedin />
        </A>
        <A href="https://twitter.com/sprintlio" aria-label="Sprintlio Twitter feed">
          <Social.Twitter />
        </A>
        <A href="https://pinterest.com/sprintlio/" aria-label="Sprintlio Pinterest page">
          <Social.Pinterest />
        </A>
      </SocialWrap>
      <Box mt={[20, 20, 0]} textAlign={['center', 'center', 'right']}>
        <Box fontWeight="500">
          Copyright Sprintlio Inc. {DateTime.local().toFormat('y')}. All rights reserved.
        </Box>
        <Box mt={0}>
          <Link to="/security">Security</Link>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <Link to="/terms">Terms of Use</Link>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <Link to="/privacy">Privacy Policy</Link>
        </Box>
      </Box>
    </Inner>
  </Outer>
);

export default Footer;
